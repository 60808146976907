import { isResponseError, ResponseError } from '@marketplace-web/shared/api-client'
import { getHomepageTab } from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import { HomepageBlocksDto } from 'types/dtos/homepage-blocks'

import { FetchHomepageBlocksOptions } from '../types'

export const EMPTY_RESPONSE_ERROR = {
  status: 200,
  code: ResponseCode.Ok,
  message: 'empty',
  errors: [],
  exception: null,
} as const satisfies ResponseError

export const getHomepageBlocks = async ({
  tab,
  ...options
}: FetchHomepageBlocksOptions): Promise<HomepageBlocksDto | ResponseError> => {
  const tabResponse = await getHomepageTab({ tabName: tab.name, ...options })

  if (isResponseError(tabResponse)) return tabResponse

  const { blocks } = tabResponse
  if (blocks.length === 0) return EMPTY_RESPONSE_ERROR

  return {
    blocks,
    next_page_token: tabResponse.pagination.next_page_token,
    show_load_more_button: tabResponse.load_more_button,
  }
}
