'use client'

import { Fragment } from 'react'

import { BlockEntityType, ThumbnailsBlockStyle } from 'constants/home'
import {
  HomepageBlockEntityModel,
  HomepageItemBlockModel,
  HomepageBlocksModel,
  HomepageItemModel,
} from 'types/models/homepage-blocks'
import { BannersBlockModel, ThumbnailsBlockModel } from 'types/models/homepage-layouts'
import { GenericPromoBoxModel } from 'types/models/generic-promo-box'
import useStickyPromoBox from '_libs/common/braze/hooks/useStickyPromoBox'
import ExposureBlock from 'components/ExposureBlock'
import useBrazePromoBoxes from '_libs/common/braze/hooks/useBrazePromoBoxes'
import { PromoBoxType } from '_libs/common/braze/constants'
import { useHomeInserts } from '@marketplace-web/domain/vas'
import {
  useHomeListerActivationBanner,
  HomeListerActivationBanner,
} from '@marketplace-web/domain/banners'

import ItemsBlock from './ItemsBlock'
import { HorizontalRowsBlock, ThumbnailsBlock, BannersBlock } from './Layouts'
import BlockArrangement from '../common/BlockArrangement'
import { useHomeContext } from '../HomeProvider'
import useTabs from '../hooks/useTabs'
import Item from './Item'
import Header from './Header'
import BrazePromoBox from './BrazePromoBox'
import useInsertBrazePromoBoxes from '../hooks/useInsertBrazePromoBoxes'
import useRemoveEmptySlots from '../hooks/useRemoveEmptySlots'
import DynamicSpacer from '../common/DynamicSpacer'

type Props = {
  blocks: HomepageBlocksModel
}

const HomeBlocks = ({ blocks: data }: Props) => {
  const listerActivationBannerProps = useHomeListerActivationBanner()
  const { getStickyPromoBox } = useStickyPromoBox()
  const { homepageSessionId } = useHomeContext()
  const { currentTab } = useTabs()

  const isFeedPromoBoxEnabled = currentTab.feed.isPromoBoxEnabled
  const brazePromoBoxes = useBrazePromoBoxes(PromoBoxType.Braze)
  const enabledPromoBoxes = isFeedPromoBoxEnabled ? brazePromoBoxes : []

  const blocksWithPromoBoxes = useInsertBrazePromoBoxes({
    blocks: data.blocks,
    isFeedPromoBoxEnabled,
  })
  const blocks = useRemoveEmptySlots(blocksWithPromoBoxes)
  const { renderClosetOrAdComponent } = useHomeInserts({
    ...currentTab.feed,
    homepageSessionId,
  })

  const renderHomePageGenericBlock = (block: HomepageItemBlockModel, index: number) => {
    return (
      <BlockArrangement>
        <ItemsBlock
          {...block}
          promoBox={currentTab.isPromoBoxEnabled ? getStickyPromoBox() : null}
          homepageSessionId={homepageSessionId}
          position={index + 1}
        />
      </BlockArrangement>
    )
  }

  const renderThumbnailsBlock = (block: ThumbnailsBlockModel, index: number) => {
    if (!block.elements.length) return null

    if (block.style === ThumbnailsBlockStyle.TwoHorizontalRows) {
      return (
        <BlockArrangement>
          <HorizontalRowsBlock
            {...block}
            position={index + 1}
            homepageSessionId={homepageSessionId}
          />
        </BlockArrangement>
      )
    }

    return (
      <BlockArrangement>
        <ThumbnailsBlock {...block} homepageSessionId={homepageSessionId} position={index + 1} />
      </BlockArrangement>
    )
  }

  const renderBannersBlock = (block: BannersBlockModel, index: number) => {
    if (!block.elements.length) return null

    return (
      <BlockArrangement>
        <BannersBlock {...block} position={index + 1} homepageSessionId={homepageSessionId} />
      </BlockArrangement>
    )
  }

  const renderItem = (item: HomepageItemModel, index: number) => {
    return (
      <Item
        item={item}
        position={index + 1}
        homepageSessionId={homepageSessionId}
        brazePromoBoxes={enabledPromoBoxes}
      />
    )
  }

  const renderAdOrCloset = (index: number) => {
    return (
      <BlockArrangement afterBlockSpacer={false}>
        {renderClosetOrAdComponent({
          position: index + 1,
          id: `ad-${index}`,
          suffix: <DynamicSpacer phones="XLarge" tabletsUp="X3Large" />,
        })}
      </BlockArrangement>
    )
  }

  const renderListerActivationBanner = (index: number) => (
    <BlockArrangement afterBlockSpacer={false}>
      <HomeListerActivationBanner {...listerActivationBannerProps} index={index} />
    </BlockArrangement>
  )

  const renderBrazePromoBox = (promoBox: GenericPromoBoxModel, index: number) => (
    <BrazePromoBox promoBox={promoBox} position={index + 1} brazePromoBoxes={enabledPromoBoxes} />
  )

  const renderBlock = (block: HomepageBlockEntityModel, index: number) => {
    switch (block.type) {
      case BlockEntityType.ItemBoxBlock:
        return renderHomePageGenericBlock(block.entity, index)
      case BlockEntityType.ThumbnailsBlock:
        return renderThumbnailsBlock(block.entity, index)
      case BlockEntityType.BannersBlock:
        return renderBannersBlock(block.entity, index)
      case BlockEntityType.AdOrCloset:
        return renderAdOrCloset(index)
      case BlockEntityType.Item:
        return renderItem(block.entity, index)
      case BlockEntityType.ListerActivationBanner:
        return renderListerActivationBanner(index)
      case BlockEntityType.BrazePromobox:
        return renderBrazePromoBox(block.entity, index)
      default:
        return null
    }
  }

  let index = -1

  return (
    <div className="homepage-blocks" data-testid="homepage-blocks">
      {blocks.map(block => {
        if (block.type === BlockEntityType.ExposureBlock)
          return <ExposureBlock {...block.entity} key={block.entity.test_id} />

        if (block.type === BlockEntityType.Header)
          return <Header {...block.entity} key={block.entity.title} />

        if (block.type === BlockEntityType.ListerActivationBanner) {
          const banner = listerActivationBannerProps.generateListerActivationBannerItem(index + 1)
          if (!banner) return null
        }

        index += 1

        return <Fragment key={index}>{renderBlock(block, index)}</Fragment>
      })}
    </div>
  )
}

export default HomeBlocks
