import useBrazePromoBoxes from './useBrazePromoBoxes'
import { PromoBoxType } from '../constants'

const useStickyPromoBox = () => {
  const brazeStickyPromoBox = useBrazePromoBoxes(PromoBoxType.BrazeSticky)[0]
  let wasPromoBoxShown = false

  const getStickyPromoBox = () => {
    if (wasPromoBoxShown) return null
    wasPromoBoxShown = true

    return brazeStickyPromoBox ?? null
  }

  return {
    getStickyPromoBox,
  }
}

export default useStickyPromoBox
