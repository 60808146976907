import { useMemo } from 'react'

import { HomepageBlockEntityModel } from 'types/models/homepage-blocks'
import { BlockEntityType, SINGLE_SLOT_BLOCKS } from 'constants/home'
import {
  isPromoBoxIndex,
  useFloatingPromoBox,
} from '_libs/common/braze/hooks/useBrazeFloatingPromoBox'

type Props = {
  blocks: Array<HomepageBlockEntityModel>
  isFeedPromoBoxEnabled: boolean
}

const useInsertBrazePromoBoxes = ({ blocks, isFeedPromoBoxEnabled }: Props) => {
  const { getFloatingPromoBox } = useFloatingPromoBox()

  const blocksWithPromoBoxes = useMemo(() => {
    if (!isFeedPromoBoxEnabled) return blocks

    const result: Array<HomepageBlockEntityModel> = []
    let feedItemIndex = 0

    function push(block: HomepageBlockEntityModel) {
      if (SINGLE_SLOT_BLOCKS.includes(block.type)) feedItemIndex += 1
      result.push(block)
    }

    blocks.forEach(block => {
      if (isPromoBoxIndex(feedItemIndex)) {
        const entity = getFloatingPromoBox(feedItemIndex)
        if (entity) push({ type: BlockEntityType.BrazePromobox, entity })
      }

      push(block)
    })

    return result
  }, [blocks, getFloatingPromoBox, isFeedPromoBoxEnabled])

  return blocksWithPromoBoxes
}

export default useInsertBrazePromoBoxes
